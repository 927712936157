#jcont {
	right: 56px !important;
}

/* hide the original widget - that there were no two labels on the screen*/
#jivo_chat_widget {
	display: none;
}

/* the default style - for offline messages if no one is online */
#jivo_custom_widget {
	background-color: #2962ff;
	position: absolute;
	right: 0.22rem;
	bottom: 0.4rem;
	width: 48px;
	height: 48px;
	border-radius: 24px;
	z-index: 300000;
	cursor: pointer;
	background-image: url('/assets/icons/live-chat.svg');
	background-size: 24px;
	background-position: center;
	background-repeat: no-repeat;
}

/* when you hover the label should be shifted to the right by 3px */
#jivo_custom_widget:hover {
	bottom: 1.1rem;
}

/* if there are operators online - show other label*/
#jivo_custom_widget.jivo_online {
	background-color: #505050;
}

#jivo_custom_widget.jivo_online:hover {
	background-color: #606060;
	right: 0.22rem;
	bottom: 0.4rem;
}
